<template>
  <Head title="Log in" />

  <jet-authentication-card>
    <jet-validation-errors />

    <div v-if="status">
      {{ status }}
    </div>

    <div class="fixed fixed-center bg-grey-1 text-black">
      <form @submit.prevent="submit">
        <q-card
          square
          class=""
          style="width: 400px; padding:50px; height:480px"
        >
          <div class="q-pb-lg">
            <img :src="`/img/logo.png`">
          </div>

          <q-input
            id="email"
            v-model="form.email"
            name="email"
            type="text"
            label="Email"
            required
          />
          <q-input
            id="password"
            v-model="form.password"
            name="password"
            type="password"
            label="Password"
            required
            autocomplete="current-password"
          />

          <div class="q-py-sm">
            <input
              v-model="form.remember"
              type="checkbox"
              name="remember"
            > Remember Me
          </div>

          <q-card-actions>
            <q-btn
              type="submit"
              class="fit"
              color="secondary"
              :disabled="form.processing"
            >
              Login
            </q-btn>
          </q-card-actions>

          <div class="text-center q-pt-lg">
            <Link
              v-if="canResetPassword"
              :href="route('password.request')"
            >
              Forgot your password?
            </Link>
          </div>
        </q-card>
      </form>
    </div>
  </jet-authentication-card>
</template>

<script>
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'

import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
import { Head, Link } from '@inertiajs/vue3'
export default {
	name: 'JetLogin',
	components: {
		Head,
		JetAuthenticationCard,
		JetValidationErrors,
		Link,
	},
	props: {
		canResetPassword: Boolean,
		status: String
	},
	data () {
		return {
			form: this.$inertia.form({
				email: '',
				password: '',
				remember: false
			})
		}
	},
	methods: {
		submit () {
			this.form
				.transform(data => ({
					... data,
					remember: this.form.remember ? 'on' : ''
				}))
				.post(this.route('login'), {
					onFinish: () => this.form.reset('password'),
				})
		}
	}
}
</script>